const buildNumber = (valueOnlyNumbers, firstSequence, secondSequence, thirdSequence) => {
    let buildedNumber = "";

    if (valueOnlyNumbers.length < 2) buildedNumber = `(${firstSequence}`;
    else if (valueOnlyNumbers.length === 2) buildedNumber = `(${firstSequence})`;
    else if (valueOnlyNumbers.length < 7) buildedNumber = `(${firstSequence})${secondSequence}`;
    else if (valueOnlyNumbers.length <= 11) buildedNumber = `(${firstSequence})${secondSequence}-${thirdSequence}`;

    return buildedNumber;
}

const normalizeCellphoneNumber = (value, prevValue) => {
    if (!value) return value;

    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    if (valueOnlyNumbers.length > 11 || value.length > 14) return prevValue;
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

    const firstSequence = valueOnlyNumbers.slice(0, 2);
    const secondSequence = valueOnlyNumbers.slice(2, 7);
    const thirdSequence = valueOnlyNumbers.slice(7, 11);

    return buildNumber(valueOnlyNumbers, firstSequence, secondSequence, thirdSequence)
}

export default normalizeCellphoneNumber;