import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import HomeFacaParte from "../HomeFacaParte/HomeFacaParte";
import { getLancamentos } from "../../redux/actions/HomeLancamentosActions";

import leftChevron from "../../assets/images/left-chevron.png";
import rightChevron from "../../assets/images/right-chevron.png";
import "./HomeLancamentos.scss";

export class HomeLancamentos extends Component {
    async componentDidMount() {
        await this.props.getLancamentos();
    }

    render() {
        const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
                type="button"
            >
                <img 
                    src={leftChevron} 
                    alt="Seta para a esquerda"
                />
            </button>
        );
        
        const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
                type="button"
            >
                <img 
                    src={rightChevron} 
                    alt="Seta para a esquerda"
                />
            </button>
        );

        return (
            <section className="home-lancamentos">
                <div className="container-fluid w-95-p">
                    <h2 className="f-montserrat f-w-700 f-32 text-center m-b-64">
                        Lançamentos
                        <span className="text-pink"> mais recentes</span>
                    </h2>
                    <SlickCarousel
                        dots={false}
                        infinite={true}
                        slidesToShow={4}
                        slidesToScroll={1}
                        autoplay={false}
                        speed={500}
                        autoplaySpeed={0}
                        cssEase={"linear"}
                        arrows={true}
                        initialSlide={2}
                        nextArrow={<SlickArrowRight />}
                        prevArrow={<SlickArrowLeft />}
                        centerMode={true}
                        className="slick-slider d-none d-xl-block home-lancamentos-slickcarousel slick-initialized"
                    >
                        { this.props.homeLancamentosReducer.lancamentos.map((lancamento, index) => (
                            <div 
                                className="home-lancamentos-slide-item-magazine"
                                key={`home-lancamentos-${index}`}
                            >
                                <div 
                                    className="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <img src={lancamento.image} alt={lancamento.imageAlt} />
                                    <div className="f-lato f-w-600 bg-white home-lancamentos-lancamento-slide-info p-24 d-flex justify-flex-start align-flex-center flex-column">
                                        {/* <span className="f-14 f-l-h-22-4 text-grey">{`${lancamento.code} | ${lancamento.date}`} </span> */}
                                        <span className="f-20 f-l-h-28-4 text-black">{`${lancamento.title}`}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SlickCarousel>
                    <SlickCarousel
                        dots={false}
                        infinite={false}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={false}
                        speed={500}
                        autoplaySpeed={0}
                        cssEase={"linear"}
                        arrows={true}
                        initialSlide={0}
                        centerMode={true}
                        
                        className="d-block d-xl-none home-lancamentos-mais-recentes-mobile"
                    >
                        { this.props.homeLancamentosReducer.lancamentos.map((lancamento, index) => (
                            <div 
                                className="home-lancamentos-slide-item-magazine"
                                key={`home-lancamentos-${index}`}
                            >
                                <div 
                                    className="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <img src={lancamento.image} alt={lancamento.imageAlt} />
                                    <div className="f-lato f-w-600 bg-white home-lancamentos-lancamento-slide-info p-24 d-flex justify-flex-start align-flex-center flex-column">
                                        {/*<span className="f-14 f-l-h-22-4 text-grey">{`${lancamento.code} | ${lancamento.date}`}</span>*/}
                                        <span className="f-18 f-l-h-28-8 text-black">{`${lancamento.title}`}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SlickCarousel>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({ homeLancamentosReducer: state.homeLancamentosReducer });
const mapDispatchToProps = dispatch => bindActionCreators({ getLancamentos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeLancamentos);