import "./TheButton.scss"

import If from "../If/If";

const TheButton = props => (
    <div className={props.containerClass ? props.containerClass : ""}>
        <If test={props.onClick}>
            <button onClick={props.onClick} className={`${props.className} btn the-button`}>{props.text}</button>
        </If>
        <If test={!props.onClick}>
            <a href={props.path} className={`${props.className} btn the-button`}>{props.text}</a>
        </If>
    </div>
)

export default TheButton