import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import TheModal from "../TheModal/TheModal";
import { setEsqueciSenhaFormOpened } from "../../redux/actions/Home";
import normalizeCellphoneNumber from "../../redux/normalize/normalizeCellphoneNumber";

import "./EsqueciSenhaForm.scss";
import assetImagemModalEsqueciASenha from "../../assets/images/home/imagem-modal-esqueci-a-senha.png";

export class EsqueciSenhaForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loginMethod: 'celular',
        }

        this.onChangeLoginMethod = this.onChangeLoginMethod.bind(this)
    }

    hideModal() {
        this.props.setEsqueciSenhaFormOpened(false)
    }

    onChangeLoginMethod(event) {
        this.setState({ loginMethod: event.target.value })
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <TheModal className="esqueci-senha-modal">
                <button className="the-modal-close" onClick={ () => this.hideModal() }>X</button>

                <div id="perguntas-frequentes" className="esqueci-senha-form-modal">
                    <form  id="login-form" role="form">
                    
                        <div className="row">
                            <div className="col-12">
                                <h2 className="f-montserrat f-w-500 f-20 f-l-h-33-6 text-black text-center">
                                    Esqueci <span className="text-pink f-w-700">minha senha</span>
                                </h2>
                            </div>
                        </div>

                        <div className="row m-b-25">
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-5 d-none d-md-block">
                                <img
                                    src={ assetImagemModalEsqueciASenha }
                                    alt="Tablet e celular"
                                />
                            </div>
                            <div className="col-12 col-md-7">
                                <div className="row m-b-25">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center" onChange={this.onChangeLoginMethod}>
                                            <label htmlFor="loginMethod" className="input-radio">
                                                <input type="radio" name="loginMethod" value="celular" defaultChecked />
                                                <span>Celular</span>
                                            </label>

                                            <label htmlFor="loginMethod" className="input-radio">
                                                <input type="radio" name="loginMethod" value="email" />
                                                <span>E-mail</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p className={ `${this.state.loginMethod === 'celular' ? 'f-16 text-silver' : 'd-none'}` }>Preencha seu celular com DDD no campo abaixo para receber sua senha via SMS dentro de alguns minutos.</p>
                                        <p className={ `${this.state.loginMethod === 'email' ? 'f-16 text-silver' : 'd-none'}` }>Preencha seu celular com DDD no campo abaixo para receber sua senha via SMS dentro de alguns minutos.</p>
                                    </div>
                                </div>

                                <div className={ `${this.state.loginMethod === 'celular' ? 'row m-b-25 d-block' : 'd-none'}` }>
                                    <div className="col-12">
                                        <label htmlFor="celular" className="f-14 text-silver">Celular</label>
                                        <Field 
                                            component="input"
                                            name="celular"
                                            type="text"
                                            placeholder="(00) 00000-0000"
                                            normalize={ normalizeCellphoneNumber }
                                        />
                                        <p className="f-14 text-orange m-t-15 m-b-4">Este número de celular não está cadastrado no Clube de Revistas.</p>
                                        <p className="f-14">Corrija seu número acima ou <a href="#" className="text-pink">assine</a></p>
                                    </div>
                                </div>

                                <div className={ `${this.state.loginMethod === 'email' ? 'row m-b-25 d-block' : 'd-none'}` }>
                                    <div className="col-12">
                                        <label htmlFor="email" className="f-14 text-silver">Email</label>
                                        <Field 
                                            component="input"
                                            name="email"
                                            type="text"
                                            placeholder="Digite seu email"
                                        />
                                        <p className="f-14 text-orange m-t-15 m-b-4">Este email não está cadastrado no Clube de Revistas.</p>
                                        <p className="f-14">Corrija seu email acima ou <a href="#" className="text-pink">assine</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row m-b-25">
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>

                        <div className="row m-b-25">
                            <div className="col-12 text-center">
                                <button className="the-button bg-pink btn-md text-white">
                                    Entrar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </TheModal>
        )
    }
}

EsqueciSenhaForm = reduxForm({
    form: "esqueciSenhaForm",
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch) => { },
    onSubmitFail: () => { return false }
})(EsqueciSenhaForm);

const mapStateToProps = state => ({
    HomeReducer: state.HomeReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    setEsqueciSenhaFormOpened,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EsqueciSenhaForm);