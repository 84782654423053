import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCategoriesFromJson } from "../../redux/actions/Menu";

import {
    setFaqOpened,
    setLoginFormOpened
} from "../../redux/actions/Home";
import {
    getCapasRevistasFromJson
} from "../../redux/actions/Menu";
import {
    setValidLogin
} from "../../redux/actions/AuthActions";

import If from "../If/If";

import AssetLogoHeaderBlack from "../../assets/images/landing-page-1/logo-header-black.png"

import "./HomeMenu.scss"
export class HomeMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showCategories: false,
            isOpenedSubCategories: false,
            isShowTodas: false,
            isOpenedDesktopMenu: false,
            categories: [],
            allCategories: [],
            activedCategoryRevistas: [],
            activedCategory: 'Categorias',
            opened: false,
        }

        this.props.getCapasRevistasFromJson();
    }

    async componentDidMount() {
        this.setInitialCategories()
        await this.props.getCapasRevistasFromJson();
    }

    openLink(link) {
        console.log('openLink')
        console.log('link', link)

        window.open(link, '_blank')
    }

    setInitialCategories() {
        fetch("data.json", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then(data => {
                this.setState({
                    categories: data.common.categories,
                    allCategories: data.common.categories
                })
            })
    }

    toggleShowCategories() {
        let showCategories = !this.state.showCategories

        this.setState({
            showCategories,
        })
    }

    enterSubCategories(category) {
        this.setState({
            isOpenedSubCategories: true,
            activedCategory: category.name,
            categories: category.subcategorias,
        })
    }

    updateCapasRevistas(category) {
        if (category.subcategorias) {
            import(`../../assets/images/capa-revistas/0categorias-capas/${category.nomePasta}/1.jpg`)
                .then(image1 => {
                    import(`../../assets/images/capa-revistas/0categorias-capas/${category.nomePasta}/2.jpg`)
                        .then(image2 => {
                            import(`../../assets/images/capa-revistas/0categorias-capas/${category.nomePasta}/3.jpg`)
                                .then(image3 => {
                                    this.setState({
                                        activedCategoryRevistas: [
                                            image1,
                                            image2,
                                            image3
                                        ]
                                    })
                                })
                        })
                })
        } else {
            import(`../../assets/images/capa-revistas/${category.nomePasta}/1.jpg`)
                .then(image1 => {
                    import(`../../assets/images/capa-revistas/${category.nomePasta}/2.jpg`)
                        .then(image2 => {
                            import(`../../assets/images/capa-revistas/${category.nomePasta}/3.jpg`)
                                .then(image3 => {
                                    this.setState({
                                        activedCategoryRevistas: [
                                            image1,
                                            image2,
                                            image3
                                        ]
                                    })
                                })
                        })
                })
        }
    }

    backToInitialCategories() {
        this.setInitialCategories()

        this.setState({
            activedCategory: 'Categorias',
            isOpenedSubCategories: false,
            isShowTodas: false,
            activedCategoryRevistas: []
        })
    }

    openDuvidas() {
        this.setState({ opened: false })
        this.props.setFaqOpened(true)
    }

    openLoginForm() {
        window.location.href = "https://www.clubederevistas.com/login.php";
        /*
        this.setState({ opened: false })
        this.props.setLoginFormOpened(true)
        */
    }

    showAllRevistas() {
        this.setState({
            isShowTodas: true
        })
    }

    openDesktopMenu() {
        let isOpenedDesktopMenu = this.state.isOpenedDesktopMenu
        isOpenedDesktopMenu = !isOpenedDesktopMenu

        this.setState({
            isOpenedDesktopMenu
        })
    }

    showCapaRevistas(categorie) {
        import(`../../assets/images/capa-revistas/0categorias-capas/${categorie.nomePasta}/1.jpg`)
            .then(image1 => {
                import(`../../assets/images/capa-revistas/0categorias-capas/${categorie.nomePasta}/2.jpg`)
                    .then(image2 => {
                        import(`../../assets/images/capa-revistas/0categorias-capas/${categorie.nomePasta}/3.jpg`)
                            .then(image3 => {
                                this.setState({
                                    activedCategoryRevistas: [
                                        image1,
                                        image2,
                                        image3
                                    ]
                                })
                            })
                    })
            })
    }

    closeMenuOnClickOutside(evt) {
        console.log(evt.target);
        if (evt.target.className === "home-menu-desktop-overlay home-menu-desktop-opened") {
            this.setState({
                isOpenedDesktopMenu: false
            })
        }
    }

    render() {
        const { validLogin } = this.props.authReducer;

        return (
            <nav className="home-menu">
                <div className="home-menu-container">
                    <div className="home-menu-column">
                        <a href="#banner-home">
                            <img className="home-menu-logo" src={AssetLogoHeaderBlack} />
                        </a>
                    </div>
                    <div className="d-block d-lg-none home-menu-column home-menu-column-right">
                        <button className="home-menu-hamburguer"
                            onClick={() => this.setState({ opened: true })}></button>
                    </div>

                    <div className="d-none d-lg-flex home-menu-column home-menu-desktop-column-right">
                        <div className="home-menu-desktop-left">
                            <ul className="home-menu-desktop-link-list">
                                <li className={`${this.state.isOpenedDesktopMenu ? 'home-menu-desktop-link-active' : ''}`}>
                                    <a href="#" onClick={() => this.openDesktopMenu()} className="home-menu-desktop-link-arrow">Categorias</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.openDuvidas()} >Dúvidas</a>
                                </li>
                            </ul>
                        </div>
                        <div className="home-menu-desktop-right">

                            <form action="https://clubederevistas.com/search.php?search-q=busca" method="get" className="home-menu-desktop-form">
                                <input className="home-menu-desktop-search-input"
                                    type="text" placeholder="Pesquisar revista..." name="search-q" />
                            </form>

                            <If test={!this.props.authReducer.validLogin}>
                                <a href="#" className="home-menu-desktop-button-outline" onClick={() => this.openLoginForm()}>Entrar</a>
                                {/* <a onClick={ () => this.props.setValidLogin(true) } className="home-menu-desktop-button-outline">Entrar</a> */}
                                {/* <a href="https://clubederevistas.com/login.php" className="home-menu-desktop-button-outline">Entrar</a> */}
                                <a
                                    className="home-menu-desktop-button"
                                    href="https://ads.clubederevistas.com/lp3/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Faça parte do Clube</a>
                            </If>
                            <If test={this.props.authReducer.validLogin}>
                                <span
                                    className="home-menu-desktop-button-outline cursor-pointer"
                                    onClick={() => this.props.setValidLogin(false)}
                                >Sair</span>
                            </If>
                        </div>
                    </div>
                </div>

                <div className={`home-menu-desktop-overlay ${this.state.isOpenedDesktopMenu ? 'home-menu-desktop-opened' : ''}`} onClick={(evt) => this.closeMenuOnClickOutside(evt)}>
                    <div className="home-menu-desktop">
                        <div className="home-menu-desktop-container">



                            <div className="row m-b-15">
                                <div className="col-6">
                                    <a href="#" className={`home-menu-desktop-vertudo ${this.state.isOpenedSubCategories || this.state.isShowTodas ? 'd-none' : 'd-inline-block'}`} onClick={() => this.showAllRevistas()}>Ver tudo</a>
                                    <a href="#"
                                        onClick={() => this.backToInitialCategories()}
                                        className={`home-menu-back-navigation ${this.state.isOpenedSubCategories || this.state.isShowTodas ? 'd-inline-block' : 'd-none'}`}>
                                        <span>Voltar para categorias</span>
                                    </a>
                                </div>
                                <div class="col-6 text-right">
                                    <a href="#" 
                                        class="home-menu-desktop-vertudo"
                                        onClick={() => this.setState({isOpenedDesktopMenu: false })}
                                    >
                                        Fechar
                                    </a>
                                </div>
                            </div>

                            <div className="row m-b-15">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>

                            <div className="home-menu-desktop-scroll">
                                <If test={this.state.isShowTodas}>
                                    <div className="row m-b-10">
                                        <div className="col-12">
                                            <h5 className="home-menu-desktop-category-name p-l-5">Todos os títulos</h5>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="home-menu-desktop-todas">
                                                {this.state.allCategories.map((categorie, index) => (
                                                    <ul key={`category-desk-${categorie.name}-${index}`}>
                                                        <h6>{categorie.name}</h6>

                                                        <If test={categorie && categorie.subcategorias}>
                                                            {categorie.subcategorias.map((subCategoria, index) => (
                                                                <li>
                                                                    <a href={subCategoria.link}>{subCategoria.name}</a>
                                                                </li>
                                                            ))}
                                                        </If>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </If>

                                <If test={!this.state.isShowTodas}>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row m-b-10">
                                                <div className="col-12">
                                                    <h5 className="home-menu-desktop-category-name">{this.state.activedCategory}</h5>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="home-menu-desktop-categories">
                                                        <ul>
                                                            {this.state.categories.map((categorie, index) => (
                                                                <li key={`category-desktop-${categorie.name}-${index}`}>
                                                                    <If test={categorie.subcategorias}>
                                                                        <a href="#"
                                                                            onClick={() => this.enterSubCategories(categorie)}
                                                                            onMouseEnter={() => this.updateCapasRevistas(categorie)}
                                                                            onMouseLeave={() => this.setState({ activedCategoryRevistas: [] })}>
                                                                            {categorie.name}
                                                                        </a>
                                                                    </If>

                                                                    <If test={!categorie.subcategorias}>
                                                                        <a href={categorie.link}
                                                                            onMouseEnter={() => this.updateCapasRevistas(categorie)}
                                                                            onMouseLeave={() => this.setState({ activedCategoryRevistas: [] })}>
                                                                            {categorie.name}
                                                                        </a>
                                                                    </If>

                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-6">
                                            <div className="home-menu-desktop-exibicao">
                                                <If test={this.state.activedCategoryRevistas.length > 0}>

                                                    <img src={this.state.activedCategoryRevistas[0] ? this.state.activedCategoryRevistas[0].default : ''}
                                                        className="home-menu-desktop-exibicao-revista exibicao-revista-1" />

                                                    <img src={this.state.activedCategoryRevistas[1] ? this.state.activedCategoryRevistas[1].default : ''}
                                                        className="home-menu-desktop-exibicao-revista exibicao-revista-2" />

                                                    <img src={this.state.activedCategoryRevistas[2] ? this.state.activedCategoryRevistas[2].default : ''}
                                                        className="home-menu-desktop-exibicao-revista exibicao-revista-3" />

                                                </If>
                                            </div>
                                        </div>
                                    </div>
                                </If>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`home-menu-overlay ${this.state.opened ? 'home-menu-overlay-opened' : ''}`}>
                    <div className="home-menu-aside">
                        <div className="home-menu-header">
                            <div className="home-menu-aside-container">
                                <div className="home-menu-aside-column">
                                    <form action="https://clubederevistas.com/search.php?search-q=busca" method="get">
                                        <input
                                            className={`home-menu-search-input ${this.state.isOpenedSubCategories ? 'd-none' : 'd-block'}`}
                                            type="text"
                                            placeholder="Pesquisar revista..."
                                            name="search-q"
                                        />
                                    </form>

                                    <a href="#"
                                        onClick={() => this.backToInitialCategories()}
                                        className={`home-menu-back-navigation ${this.state.isOpenedSubCategories ? 'd-block' : 'd-none'}`}>
                                        <span>Voltar para categorias</span>
                                    </a>
                                </div>
                                <div className="home-menu-aside-column home-menu-aside-column-right">
                                    <button className="home-menu-close"
                                        onClick={() => this.setState({ opened: false })}></button>
                                </div>
                            </div>
                        </div>

                        <div className="home-menu-content">
                            <ul className="home-menu-items">
                                <li className={`home-menu-item-has-subitems ${this.state.showCategories ? 'home-menu-item-opened' : ''}`}>
                                    <a href="#" onClick={() => this.toggleShowCategories()}>{this.state.activedCategory}</a>
                                </li>

                                <ul className={`home-menu-subitems d-none ${this.state.showCategories ? 'd-block' : ''}`}>
                                    {this.state.categories.map((categorie, index) => (
                                        <li key={`category-${categorie.name}-${index}`}>

                                            <If test={categorie.subcategorias}>
                                                <a href="#" className="home-menu-has-subcategories" onClick={() => this.enterSubCategories(categorie)} >{categorie.name}</a>
                                            </If>

                                            <If test={!categorie.subcategorias}>
                                                <a
                                                    href={categorie.link}
                                                >{categorie.name}</a>
                                            </If>
                                        </li>
                                    ))}
                                </ul>

                                <li>
                                    <a href="#" onClick={() => this.openDuvidas()} >
                                        Dúvidas
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="home-menu-footer">
                            <If test={validLogin === false}>
                                <a
                                    className="home-menu-btn-facaparte"
                                    href="#"
                                    target="_blank"
                                    onClick={() => this.openLink('https://ads.clubederevistas.com/lp3/')}
                                // onClick={ () => this.setState({ opened: false }) }
                                >
                                    Faça parte do Clube
                                </a>
                            </If>

                            {/* <a href="#" onClick={ () => this.openLoginForm() }>Entrar</a> */}

                            <If test={validLogin === true}>
                                <a
                                    // href="https://clubederevistas.com/login.php"
                                    href="#"
                                    onClick={() => this.props.setValidLogin(false)}
                                >Sair
                                </a>
                            </If>

                            <If test={validLogin === false}>
                                <a
                                    // href="https://clubederevistas.com/login.php"
                                    href="#"
                                    // onClick={ () => this.props.setValidLogin(true) }
                                    onClick={() => this.openLoginForm()}
                                >
                                    Entrar
                                </a>
                            </If>




                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    MenuReducer: state.MenuReducer,
    authReducer: state.authReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getCategoriesFromJson,
    getCapasRevistasFromJson,
    setFaqOpened,
    setLoginFormOpened,
    setValidLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeMenu);