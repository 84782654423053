const INITIAL_STATE = {
    bannersLogado: [],
}

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_HOME_BANNERS_LOGADO":
            return { ...state, bannersLogado: action.payload };
        default:
            return state;
    }
}