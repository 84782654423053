const INITIAL_STATE = { materias: [], materiasLogado: [], numMateriasLogadoToShow: 6 };

export const reducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_HOME_MATERIAS":
            return { ...state, materias: action.payload };
        case "SET_HOME_MATERIAS_LOGADO":
            return { ...state, materiasLogado: action.payload };
        case "SET_HOME_NUM_MATERIAS_LOGADO_TO_SHOW":
            return { ...state, numMateriasLogadoToShow: action.payload };
        default:
            return state;
    }
}