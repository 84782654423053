import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Row from "../Row/Row";
import Grid from "../Grid/Grid";

import {
    setFaqModalOpened,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
} from "../../redux/actions/HomeFooterActions";

import "./HomeFooter.scss";
import footerLogo from "../../assets/images/home/footer-logo.png";
import appleStoreLogo from "../../assets/images/app-store.png";
import playStoreLogo from "../../assets/images/google-play.png";

export class HomeFooter extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer className="home-footer">
                <div className="container d-none d-xl-block">
                    <Row className="align-items-center">
                        <Grid
                            cols="7"
                        >
                            <img 
                                src={ footerLogo }
                                className="home-footer-logo-desktop"
                                alt="Logo Clube de Revistas"
                            />
                        </Grid>
                        <Grid
                            cols="5"
                        >
                            <Row>
                                <Grid
                                    cols="6"
                                    className="text-right"
                                >
                                    <p 
                                        className="home-footer-text"
                                        onClick={ () => this.props.setFaqModalOpened(true) }
                                    >
                                        Perguntas frequentes (FAQ)
                                    </p>
                                    <p 
                                        className="home-footer-text"
                                        onClick={ () => this.props.setTermosCondicoesModalOpened(true) }
                                    >
                                        Termos e condições
                                    </p>
                                    <p 
                                        className="home-footer-text"
                                        onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }
                                    >
                                        Política de privacidade
                                    </p>
                                </Grid>
                                <Grid
                                    cols="6"
                                    className="d-flex flex-column align-items-end"
                                >
                                    <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en">
                                        <img
                                            src={ appleStoreLogo }
                                            className="home-footer-stores-logo m-b-10"
                                            alt="Apple Store logo"
                                        />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas">
                                        <img
                                            src={ playStoreLogo }
                                            className="home-footer-stores-logo"
                                            alt="Play Store logo"
                                        />
                                    </a>
                                </Grid>
                            </Row>
                        </Grid>
                    </Row>
                </div>
                <div className="container d-block d-xl-none">
                    <img 
                        src={ footerLogo }
                        className="home-footer-logo-desktop m-b-24"
                        alt="Logo Clube de Revistas"
                    />
                    <Row>
                        <Grid
                            cols="5"
                        >
                            <a href="https://apps.apple.com/br/app/clube-de-revistas/id1117256234?l=en">
                                <img
                                    src={ appleStoreLogo }
                                    className="home-footer-stores-logo m-b-24"
                                    alt="Apple Store logo"
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=br.zed.clubederevistas">
                                <img
                                    src={ playStoreLogo }
                                    className="home-footer-stores-logo"
                                    alt="Play Store logo"
                                />
                            </a>
                        </Grid>
                        <Grid
                            cols="7"
                        >
                            <p 
                                className="home-footer-text"
                                onClick={ () => this.props.setFaqModalOpened(true) }
                            >
                                Perguntas frequentes (FAQ)
                            </p>
                            <p 
                                className="home-footer-text"
                                onClick={ () => this.props.setTermosCondicoesModalOpened(true) }
                            >
                                Termos e condições
                            </p>
                            <p 
                                className="home-footer-text"
                                onClick={ () => this.props.setPoliticaPrivacidadeModalOpened(true) }
                            >
                                Política de privacidade
                            </p>
                        </Grid>
                    </Row>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
    setFaqModalOpened,
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeFooter);