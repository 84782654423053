import React, { Component } from "react";
import Slider from "react-slick";

import "./SlickCarousel.scss";

export class SlickCarousel extends Component {
    render () {
        return(
            <Slider 
                className={this.props.className} 
                dots={this.props.dots ? this.props.dots : false}
                dotsClass={this.props.dotsClass ? this.props.dotsClass : "slick-dots"}
                infinite={this.props.infinite ? this.props.infinite : false}
                slidesToShow={this.props.slidesToShow ? this.props.slidesToShow : 1}
                slidesToScroll={this.props.slidesToScroll ? this.props.slidesToScroll : 1}
                centerMode={this.props.centerMode ? this.props.centerMode : false}
                autoplay={this.props.autoplay ? this.props.autoplay : false}
                speed={this.props.speed ? this.props.speed : 500}
                autoplaySpeed={this.props.autoplaySpeed ? this.props.autoplaySpeed : 0}
                cssEase={this.props.cssEase ? this.props.cssEase : "linear"}
                arrows={this.props.arrows ? this.props.arrows : false}
                initialSlide={this.props.initialSlide ? this.props.initialSlide : 0}
                nextArrow={this.props.nextArrow}
                prevArrow={this.props.prevArrow}
                centerPadding={this.props.centerPadding ? this.props.centerPadding : "50px"}
            >
                { this.props.children }
            </Slider>
        );
    }
}

export default SlickCarousel;