import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import { reducer as AssinaturaLanding1Reducer } from "../reducers/AssinaturaLanding1Reducer";
import { reducer as AssinaturaLanding2Reducer } from "../reducers/AssinaturaLanding2Reducer";
import { reducer as TheFooterReducer } from "./TheModalReducer";
import { reducer as MenuLateralReducer } from "../reducers/MenuLateralReducer";
import { reducer as vantagensDoClubeLanding1Reducer } from "../reducers/VantagensDoClubeLanding1Reducer";
import { reducer as lancamentosLanding1Reducer } from "../reducers/LancamentosLanding1Reducer";
import { reducer as comoFuncionaLanding2Reducer } from "../reducers/ComoFuncionaLanding2Reducer";
import { reducer as vantagensDoClubeLanding2Reducer } from "../reducers/VantagensDoClubeLanding2Reducer";
import { reducer as assinaturaFormLanding1Reducer } from "../reducers/AssinaturaFormLanding1Reducer";
import { reducer as assinaturaFormLanding2Reducer } from "../reducers/AssinaturaFormLanding2Reducer";
import { reducer as perguntasFrequentesReducer } from "../reducers/PerguntasFrequentesReducer";

import { reducer as VariablesFromJsonReducer } from "../reducers/VariablesFromJson"

import { reducer as MenuReducer } from "../reducers/Menu";
import { reducer as HomeReducer } from "../reducers/Home";
import { reducer as MenuMobileReducer } from "../reducers/MenuMobile";
import { reducer as HomeLancamentosReducer } from "../reducers/HomeLancamentosReducer";
import { reducer as HomeFacaParteReducer } from "../reducers/HomeFacaParteReducer";
import { reducer as HomeFooterReducer } from "../reducers/HomeFooterReducer";
import { reducer as AuthReducer } from "../reducers/AuthReducer";
import { reducer as HomeTitulosDestaqueReducer } from "../reducers/HomeTitulosDestaqueReducer";
import { reducer as HomeMateriasReducer } from "../reducers/HomeMateriasReducer";
import { reducer as BannerHomeReducer } from "../reducers/BannerHomeReducer";

const rootReducer = combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    assinaturalanding1reducer: AssinaturaLanding1Reducer,
    assinaturaLanding2Reducer: AssinaturaLanding2Reducer,
    theFooterreducer: TheFooterReducer,
    menuLateralReducer: MenuLateralReducer,
    vantagensDoClubeLanding1reducer: vantagensDoClubeLanding1Reducer,
    lancamentosLanding1Reducer: lancamentosLanding1Reducer,
    comoFuncionaLanding2Reducer: comoFuncionaLanding2Reducer,
    vantagensDoClubeLanding2Reducer: vantagensDoClubeLanding2Reducer,
    assinaturaFormLanding1Reducer: assinaturaFormLanding1Reducer,
    assinaturaFormLanding2Reducer: assinaturaFormLanding2Reducer,
    perguntasFrequentesReducer: perguntasFrequentesReducer,
    homeLancamentosReducer: HomeLancamentosReducer,
    homeFacaParteReducer: HomeFacaParteReducer,
    homeFooterReducer: HomeFooterReducer,
    bannerHomeReducer: BannerHomeReducer,
    authReducer: AuthReducer,
    MenuReducer,
    HomeReducer,
    MenuMobileReducer,
    VariablesFromJsonReducer,
    HomeTitulosDestaqueReducer,
    HomeMateriasReducer,
});

export default rootReducer;