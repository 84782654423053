export const getMaterias = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["home"] &&
                data["home"]["materias-destaque"])
            {
                const materias = [];

                await data["home"]["materias-destaque"].forEach(async (materia) => {
                    const id = materia.id;
                    const revista = materia.revista;
                    const edicao = materia.edicao;
                    const data = materia.data;
                    const titulo = materia.titulo;
                    const body = materia.body;
                    const link = materia.link;
                    const cover = await require(`../../assets/images/materias/${materia.cover}`);

                    const materiaObj = {
                        id,
                        revista,
                        edicao,
                        data,
                        titulo,
                        body,
                        link,
                        cover: cover.default,
                    }

                    materias.push(materiaObj);
                });

                dispatch({ type: "SET_HOME_MATERIAS", payload: materias });
            }
        })
    }
}

export const getMateriasLogado = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["home"] &&
                data["home"]["materias-destaque-logado"])
            {
                const materias = [];

                await data["home"]["materias-destaque-logado"].forEach(async (materia) => {
                    const id = materia.id;
                    const revista = materia.revista;
                    const edicao = materia.edicao;
                    const data = materia.data;
                    const titulo = materia.titulo;
                    const body = materia.body;
                    const link = materia.link;
                    const cover = await require(`../../assets/images/materias/${materia.cover}`);

                    const materiaObj = {
                        id,
                        revista,
                        edicao,
                        data,
                        titulo,
                        body,
                        link,
                        cover: cover.default,
                    };

                    materias.push(materiaObj);
                });

                dispatch({ type: "SET_HOME_MATERIAS_LOGADO", payload: materias });
            }
        })
    }
}

export const increaseNumMateriasLogadoToShow = num => {
    return dispatch => {
        dispatch({ type: "SET_HOME_NUM_MATERIAS_LOGADO_TO_SHOW", payload: num })
    }
}