import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HomeMenu from "../../components/HomeMenu/HomeMenu"
import If from "../../components/If/If";
import PerguntasFrequentes from "../../components/PerguntasFrequentes/PerguntasFrequentes";
import LoginForm from "../../components/LoginForm/LoginForm";
import EsqueciSenhaForm from "../../components/EsqueciSenhaForm/EsqueciSenhaForm";
import FullPageHome from "../../components/FullPageHome/FullPageHome"

import {
    setFaqOpened,
    setLoginFormOpened,
} from "../../redux/actions/Home";

import "./Home.scss"
export class Home extends Component {
    render() {
        return (
           <main className="main-home">
               <HomeMenu />
               <FullPageHome />

                <If test={ this.props.HomeReducer.faqOpened }>
                    <PerguntasFrequentes />
                </If>

                <If test={ this.props.HomeReducer.loginFormOpened }>
                    <LoginForm />
                </If>

                <If test={ this.props.HomeReducer.esqueciSenhaFormOpened }>
                    <EsqueciSenhaForm />
                </If>
           </main> 
        );
    }
}

const mapStateToProps = state => ({ 
    HomeReducer: state.HomeReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setFaqOpened,
    setLoginFormOpened,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);