import React from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import App from "./containers/App/App";
//import LandingPage1 from "./containers/LandingPage1/LandingPage1";
//import LandingPage2 from "./containers/LandingPage2/LandingPage2";
import Home from "./containers/Home/Home";

import "./components/Menu/Menu.scss"

const Routes = props => (
    <BrowserRouter>
        <Switch>
            <App>
                {/*<Route path="/landing-1" component={LandingPage1} />*/}
                {/*<Route path="/landing-2" component={LandingPage2} />*/}
                <Route exact path="/" >
                    <Home />
                </Route>
                <Route path="*" >
                    <NoMatch />
                </Route>
            </App>
        </Switch>
    </BrowserRouter>
);


function NoMatch() {
    let location = useLocation();
    let destination = 'https://www2.clubederevistas.com' + location.pathname + location.search;
    if (location.pathname !== "/") {
        window.location.replace(destination);
    }
    return null;   
}

export default Routes;