import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCategoriesFromJson } from "../../redux/actions/Menu";

import {
    setFaqOpened,
    setLoginFormOpened
} from "../../redux/actions/Home";

import SlideMarcas from "../../components/SlideMarcas/SlideMarcas"
import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import If from "../If/If";

import {
    getCapasRevistasFromJson
} from "../../redux/actions/Menu";

import { getTitulos, setTitulosDestaqueCurrentLancamento } from "../../redux/actions/HomeTitulosDestaqueActions";

import "./HomeTitulosEmDestaque.scss"

export class HomeTitulosEmDestaque extends Component {
    async componentDidMount() {
        await this.props.getTitulos();

        const currentTitulo = this.props.HomeTitulosDestaqueReducer.titulos[0]

        this.props.setTitulosDestaqueCurrentLancamento(currentTitulo)
    }

    render() {
        return (
            <section id="home-titulos-destaque" className="home-titulos-destaque">
                <div className="row">
                    <div className="col-12">
                        <h2 className="home-titulos-destaque-titulo">As melhores revistas do país <b className="text-pink">ao seu alcance</b></h2>
                    </div>
                </div>

                <div className="p-y-15--">
                    <div className="home-titulos-destaque-slides">
                        <div className="home-titulos-destaque-slide">
                            <SlideMarcas />
                        </div>

                        <div className="home-titulos-destaque-slide">
                            <SlideMarcas reverse={true} />
                        </div>
                    </div>
                </div>

                <div className="home-titulos-section-home-mobile d-block d-lg-none">
                    <h3><b>Títulos</b> <span>em destaque</span></h3>
                </div>

                <div className="home-titulos-section">
                    <div className="home-titulos-section-container">
                        <div className="home-titulos-section-home">
                            <h3><b>Títulos</b> <span>em destaque</span></h3>
                        </div>

                        <div className="home-titulos-destaque-card-container">
                            <div className="home-titulos-destaque-revistas-slide">
                                <div className="container-fluid">
                                    <div className="d-block d-lg-none">
                                        <Row>
                                            <Grid cols="12">
                                                <SlickCarousel
                                                    dots={false}
                                                    infinite={true}
                                                    slidesToShow={2}
                                                    slidesToScroll={1}
                                                    autoplay={false}
                                                    speed={500}
                                                    autoplaySpeed={0}
                                                    cssEase={"linear"}
                                                    arrows={true}
                                                    initialSlide={2}
                                                    centerMode={true}
                                                    centerPadding={"50px"}
                                                >
                                                    {this.props.HomeTitulosDestaqueReducer.titulos.map((lancamento, index) => (
                                                        <div
                                                            key={`VantagensDoClubeLanding1-1-${index}`}
                                                            className="home-titulos-destaque-revistas-slide-image-container"
                                                            onClick={() => this.props.setTitulosDestaqueCurrentLancamento(lancamento)}
                                                        >

                                                            <img
                                                                className="home-titulos-destaque-revistas-slide-image"
                                                                key={`home-titulos-destaque-revistas-slide-image-${index}`}
                                                                src={lancamento.image}
                                                            />
                                                        </div>
                                                    ))}
                                                </SlickCarousel>
                                            </Grid>
                                        </Row>
                                    </div>

                                    <div className="home-titulos-detail-revista">
                                        <img src={this.props.HomeTitulosDestaqueReducer.currentTitulo ? this.props.HomeTitulosDestaqueReducer.currentTitulo.image : ''} alt="" />

                                        <div className="home-titulos-detail-revista-info">
                                            <h3>{this.props.HomeTitulosDestaqueReducer.currentTitulo ? this.props.HomeTitulosDestaqueReducer.currentTitulo.title : ''}</h3>
                                            <small>Edição {this.props.HomeTitulosDestaqueReducer.currentTitulo ? this.props.HomeTitulosDestaqueReducer.currentTitulo.numberEdicoes : ''}</small>
                                            <p>{this.props.HomeTitulosDestaqueReducer.currentTitulo ? this.props.HomeTitulosDestaqueReducer.currentTitulo.descricao : ''}</p>
                                            <a href={this.props.HomeTitulosDestaqueReducer.currentTitulo ? this.props.HomeTitulosDestaqueReducer.currentTitulo.edicoesAnteriores : ''} className="link">Leia a última edição</a>

                                        </div>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <Row>
                                            <Grid cols="12">
                                                <SlickCarousel
                                                    dots={false}
                                                    infinite={false}
                                                    slidesToShow={8}
                                                    slidesToScroll={1}
                                                    autoplay={false}
                                                    speed={3000}
                                                    autoplaySpeed={0}
                                                    arrows={false}
                                                    focusOnSelect={true}
                                                    cssEase={"linear"}
                                                >
                                                    {this.props.HomeTitulosDestaqueReducer.titulos.map((lancamento, index) => (
                                                        <div
                                                            key={`VantagensDoClubeLanding1-1-${index}`}
                                                            className="home-titulos-destaque-revistas-slide-image-container"
                                                            onClick={() => this.props.setTitulosDestaqueCurrentLancamento(lancamento)}
                                                        >

                                                            <img
                                                                className="home-titulos-destaque-revistas-slide-image"
                                                                key={`home-titulos-destaque-revistas-slide-image-${index}`}
                                                                src={lancamento.image}
                                                            />
                                                        </div>
                                                    ))}
                                                </SlickCarousel>
                                            </Grid>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    MenuReducer: state.MenuReducer,
    HomeTitulosDestaqueReducer: state.HomeTitulosDestaqueReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getCategoriesFromJson,
    getCapasRevistasFromJson,
    setFaqOpened,
    setLoginFormOpened,
    getTitulos,
    setTitulosDestaqueCurrentLancamento,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeTitulosEmDestaque);