import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import TheModal from "../TheModal/TheModal";
import { setLoginFormOpened, setEsqueciSenhaFormOpened } from "../../redux/actions/Home";
import normalizeCellphoneNumber from "../../redux/normalize/normalizeCellphoneNumber";
import normalizeCPF from "../../redux/normalize/normalizeCreditCardCPF";

import "./LoginForm.scss";
import assetImagemModalLogin from "../../assets/images/home/imagem-modal-login.png";

export class LoginForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loginMethod: 'celular',
            isSafari: false,
        }

        this.onChangeLoginMethod = this.onChangeLoginMethod.bind(this)
    }

    componentDidMount() {
        this.setState({
            isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        })
    }

    hideModal() {
        this.props.setLoginFormOpened(false)
    }

    onChangeLoginMethod(event) {
        this.setState({ loginMethod: event.target.value })
    }

    openEsqueciSenhaForm() {
        this.hideModal()
        this.props.setEsqueciSenhaFormOpened(true)
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            // 
            <TheModal className={ this.state.isSafari ? 'login-modal-fix-scroll login-modal' : 'login-modal' }>
                <button className="the-modal-close" onClick={ () => this.hideModal() }>X</button>

                <div id="perguntas-frequentes" className="login-form-modal">
                    <form  id="login-form" role="form">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="f-montserrat f-w-500 f-20 f-l-h-33-6 text-black text-center">
                                    Faça seu <span className="text-pink f-w-700">login</span>
                                </h2>

                                {/* <code>isSafari: {JSON.stringify(this.state.isSafari)}</code> */}
                            </div>
                        </div>

                        <div className="row m-b-25">
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>

                        <div className="login-modal-scroll">
                            <div className="row">
                                <div className="col-5 d-none d-md-block">
                                    <img 
                                        src={ assetImagemModalLogin }
                                        alt="Tablet e celular"
                                    />
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row m-b-25">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center" onChange={this.onChangeLoginMethod}>
                                                <label htmlFor="loginMethod" className="input-radio-new">
                                                    <input type="radio" name="loginMethod" value="celular" defaultChecked />
                                                    <i></i>
                                                    <span>Celular</span>
                                                </label>

                                                <label htmlFor="loginMethod" className="input-radio-new">
                                                    <input type="radio" name="loginMethod" value="email" />
                                                    <i></i>
                                                    <span>E-mail</span>
                                                </label>

                                                <label htmlFor="loginMethod" className="input-radio-new">
                                                    <input type="radio" name="loginMethod" value="cpf-cnpj" />
                                                    <i></i>
                                                    <span>CPF ou CNPJ</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p className={ `${this.state.loginMethod === 'celular' ? 'f-16 text-silver' : 'd-none'}` }>Informe seu número de celular com DDD para fazer o login:</p>
                                            <p className={ `${this.state.loginMethod === 'email' ? 'f-16 text-silver' : 'd-none'}` }>Informe seu email para fazer o login:</p>
                                            <p className={ `${this.state.loginMethod === 'cpf-cnpj' ? 'f-16 text-silver' : 'd-none'}` }>Informe seu CPF ou CNPJ fazer o login:</p>
                                        </div>
                                    </div>
                                    

                                    <div className={ `${this.state.loginMethod === 'celular' ? 'row m-b-25 d-block' : 'd-none'}` }>
                                        <div className="col-12">
                                            <label htmlFor="celular" className="f-14 text-silver">Celular</label>
                                            <Field 
                                                component="input"
                                                name="celular"
                                                type="text"
                                                placeholder="(00) 00000-0000"
                                                normalize={ normalizeCellphoneNumber }
                                            />
                                            {/* <span class="error-message error-message-creditCardNumber d-block">Mensagem de erro</span> */}
                                            <p className="f-14 text-orange m-t-15">Este número de celular não está cadastrado no Clube de Revistas.</p>
                                        </div>
                                    </div>

                                    <div className={ `${this.state.loginMethod === 'email' ? 'row m-b-25 d-block' : 'd-none'}` }>
                                        <div className="col-12">
                                            <label htmlFor="celular" className="f-14 text-silver">Email</label>
                                            <Field 
                                                component="input"
                                                name="celular"
                                                type="text"
                                                placeholder="Digite seu email"
                                            />
                                            <p className="f-14 text-orange m-t-15">Este email não está cadastrado no Clube de Revistas.</p>
                                        </div>
                                    </div>

                                    <div className={ `${this.state.loginMethod === 'cpf-cnpj' ? 'row m-b-25 d-block' : 'd-none'}` }>
                                        <div className="col-12">
                                            <label htmlFor="celular" className="f-14 text-silver">CPF ou CNPJ</label>
                                            <Field 
                                                component="input"
                                                name="celular"
                                                type="text"
                                                placeholder="Digite seu CPF ou CNPJ"
                                                normalize={ normalizeCPF }
                                            />
                                            <p className="f-14 text-orange m-t-15">Este CNPJ não está cadastrado no Clube de Revistas.</p>
                                        </div>
                                    </div>

                                    <div className={ `${this.state.loginMethod === 'cpf-cnpj' ? 'd-none' : 'row m-b-25 d-block' }` }>
                                        <div className="col-12">
                                            <label htmlFor="password" className="f-14 text-silver">Senha</label>
                                            <Field 
                                                component="input"
                                                name="password"
                                                type="password"
                                                placeholder="Digite sua senha"
                                            />
                                            <p className="f-14 text-orange m-t-15">Este email não está cadastrado no Clube de Revistas.</p>
                                        </div>
                                    </div>

                                    <div className="row m-b-25">
                                        <div className="col-12 text-right">
                                            <a href="#" className="f-14 text-pink" onClick={ () => this.openEsqueciSenhaForm() }>Esqueci a senha</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-b-25">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row m-b-25">
                                <div className="col-12 text-center">
                                    <button className="the-button bg-pink btn-md text-white">
                                        Entrar
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="f-14 text-orange">Aguarde. Você receberá a senha via SMS no celular informado.</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </TheModal>
        )
    }
}

LoginForm = reduxForm({
    form: "loginForm",
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch) => { },
    onSubmitFail: () => { return false }
})(LoginForm);

const mapStateToProps = state => ({
    HomeReducer: state.HomeReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    setLoginFormOpened,
    setEsqueciSenhaFormOpened,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);