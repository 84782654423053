import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./TheModal.scss";

import {
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened,
    setFAQModalOpened
} from "../../redux/actions/TheModalActions";
import {
    setTermosCondicoesModalOpened as setTermosCondicoesModalOpenedFormLanding1,
    setPoliticaPrivacidadeModalOpened as setPoliticaPrivacidadeModalOpenedFormLanding1
} from "../../redux/actions/AssinaturaFormLanding1Actions";
import {
    setModal1Opened as setModal1OpenedVantagensLanding1
} from "../../redux/actions/VantagensDoClubeLanding1Actions";
import {
    setModal1Opened as setModal1OpenedVantagensLanding2
} from "../../redux/actions/VantagensDoClubeLanding2Actions";
import { 
    setPoliticaPrivacidadeModalOpened as setTermosCondicoesModalOpenedFormLanding2,
    setTermosCondicoesModalOpened as setPoliticaPrivacidadeModalOpenedFormLanding2
} from "../../redux/actions/AssinaturaFormLanding2Actions";
import {
    setAssinaturaConcluidaModalOpened as setAssinaturaConcluidaModalOpenedLanding1
} from "../../redux/actions/AssinaturaLanding1Actions";
import {
    setVideoModalOpened as setVideoModalOpenedHomeFacaParte
} from "../../redux/actions/HomeFacaParteActions";
import {
    setFaqModalOpened as setFaqModalOpenedHomeFooter,
    setTermosCondicoesModalOpened as setTermosCondicoesModalOpenedHomeFooter,
    setPoliticaPrivacidadeModalOpened as setPoliticaPrivacidadeModalOpenedHomeFooter
} from "../../redux/actions/HomeFooterActions";
import {
    setEsqueciSenhaFormOpened,
    setLoginFormOpened
} from "../../redux/actions/Home";

export class TheModal extends Component {
    constructor(props) {
        super(props);
        
        const body = document.body
        body.classList.add('overflow-hidden')
    }
    
    componentWillUnmount() {
        const body = document.body
        body.classList.remove('overflow-hidden')
    }

    componentDidMount() {
        const _this = this;

        window.onclick = function(event) {
            const modal = document.getElementById("the-modal-container");

            if (event.target == modal) {
                _this.props.setFAQModalOpened(false);
                _this.props.setTermosCondicoesModalOpened(false);
                _this.props.setPoliticaPrivacidadeModalOpened(false);
                _this.props.setPoliticaPrivacidadeModalOpenedFormLanding1(false);
                _this.props.setTermosCondicoesModalOpenedFormLanding1(false);
                _this.props.setModal1OpenedVantagensLanding1(false);
                _this.props.setModal1OpenedVantagensLanding2(false);
                _this.props.setTermosCondicoesModalOpenedFormLanding2(false);
                _this.props.setPoliticaPrivacidadeModalOpenedFormLanding2(false);
                _this.props.setAssinaturaConcluidaModalOpenedLanding1(false);
                _this.props.setVideoModalOpenedHomeFacaParte(false);
                _this.props.setFaqModalOpenedHomeFooter(false);
                _this.props.setTermosCondicoesModalOpenedHomeFooter(false);
                _this.props.setPoliticaPrivacidadeModalOpenedHomeFooter(false);
                _this.props.setEsqueciSenhaFormOpened(false);
                _this.props.setLoginFormOpened(false);
            }
        }
    }

    render() {
        return (
            <article className="the-modal" id="the-modal">
                <div className="the-modal-wrap">
                    <div className="the-modal-container" id="the-modal-container">
                        <div className={`${this.props.className} the-modal-content`}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
    setTermosCondicoesModalOpened,
    setPoliticaPrivacidadeModalOpened,
    setFAQModalOpened,
    setTermosCondicoesModalOpenedFormLanding1,
    setPoliticaPrivacidadeModalOpenedFormLanding1,
    setModal1OpenedVantagensLanding1,
    setModal1OpenedVantagensLanding2,
    setTermosCondicoesModalOpenedFormLanding2,
    setPoliticaPrivacidadeModalOpenedFormLanding2,
    setAssinaturaConcluidaModalOpenedLanding1,
    setVideoModalOpenedHomeFacaParte,
    setFaqModalOpenedHomeFooter,
    setTermosCondicoesModalOpenedHomeFooter,
    setPoliticaPrivacidadeModalOpenedHomeFooter,
    setEsqueciSenhaFormOpened,
    setLoginFormOpened
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TheModal);