import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import "./SlideMarcas.scss";

import advantagesIcon1 from "../../assets/images/landing-page-1/advantages-icon1.png";
import advantagesIcon2 from "../../assets/images/landing-page-1/advantages-icon2.png";
import advantagesIcon3 from "../../assets/images/landing-page-1/advantages-icon3.png";
import advantagesIcon4 from "../../assets/images/landing-page-1/advantages-icon4.png";
import advantagesDevices from "../../assets/images/landing-page-1/advantages-devices.png";
import advantagesDevicesMobile from "../../assets/images/landing-page-1/advantages-devices-mobile.png";
import assetArrowPinkDown from "../../assets/images/arrow-pink-down.png";

import IconEllipse from "../IconEllipse/IconEllipse";
import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import TheButton from "../TheButton/TheButton"; 
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import { setModal1Opened, getVideoUrl, getPrincipaisTitulosImages } from "../../redux/actions/VantagensDoClubeLanding1Actions";

export class SlideMarcas extends Component {
    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.props.getVideoUrl();
        this.props.getPrincipaisTitulosImages();

        this.state = {
            isMobile: false,
        }
    }

    async componentDidMount() {
        await this.props.getPrincipaisTitulosImages();
    }

    showModal() {
        this.props.setModal1Opened(true);
    }

    hideModal() {
        this.props.setModal1Opened(false);
    }

    scrollTo(element) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop);
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                isMobile: true
            })
        }
    }

    render() {
        return (
            <article className="slide-marcas">
                <div className="d-none d-md-block">
                    <div className="container-fluid">
                        <Row>
                            <Grid cols="12">
                                <SlickCarousel
                                    dots={false}
                                    infinite={true}
                                    slidesToShow={10} 
                                    slidesToScroll={this.props.reverse ? -1 : 1}
                                    autoplay={true}
                                    speed={3000}
                                    autoplaySpeed={0}
                                    arrows={false}
                                    cssEase={"linear"}
                                >
                                    { this.props.vantagensDoClubeLanding1reducer.principaisTitulosImages.map((image, index) => (
                                        <div 
                                            key={`VantagensDoClubeLanding1-1-${index}`}
                                            className="item d-flex justify-content-center align-items-center"
                                        >

                                            {/* <code>{JSON.stringify(image)}</code> */}

                                            <img
                                                className="w-50-p-i"
                                                key={`ClubAdvantagensLanding1-1-image-${index}`}
                                                src={image}
                                                alt="Logo dos principais títulos do Brasil"
                                            />
                                        </div>
                                    ))}
                                </SlickCarousel>
                            </Grid>
                        </Row>
                    </div>
                </div>
                
                {/* MOBILE */}
                <div className="d-block d-md-none">
                    <Row>
                        <Grid cols="12">
                            <SlickCarousel
                                dots={false}
                                infinite={true}
                                slidesToShow={3} 
                                slidesToScroll={this.props.reverse ? -1 : 1}
                                autoplay={true}
                                speed={3000}
                                autoplaySpeed={0}
                                arrows={false}
                                cssEase={"linear"}
                            >
                                { this.props.vantagensDoClubeLanding1reducer.principaisTitulosImages.map((image, index) => (
                                    <div 
                                        key={`VantagensDoClubeLanding1-2-${index}`}
                                        className="item d-flex justify-content-center align-items-center slide-marcas-mobile"
                                    >
                                        <img
                                            className="slide-marcas-mobile-image"
                                            key={index}
                                            src={image}
                                            alt="Logo dos principais títulos do Brasil"
                                        />
                                    </div>
                                ))}
                            </SlickCarousel>
                        </Grid>
                    </Row>
                </div>
            </article>
        )
    }
}

const mapStateToProps = state => ({ vantagensDoClubeLanding1reducer: state.vantagensDoClubeLanding1reducer });
const mapDispatchToProps = dispatch => bindActionCreators({
    setModal1Opened, getVideoUrl, getPrincipaisTitulosImages
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SlideMarcas);