import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import If from "../If/If";
import TheButton from "../TheButton/TheButton";
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import {
    getVideo,
    setVideoModalOpened
} from "../../redux/actions/HomeFacaParteActions";

import VideoEmbed from "../VideoEmbed/VideoEmbed"

import "./HomeFacaParte.scss";
import historicoLeitura1 from "../../assets/images/historico-leitura/historico-leitura-1.png";
import leftChevron from "../../assets/images/left-chevron.png";
import rightChevron from "../../assets/images/right-chevron.png";

export class HomeFacaParte extends Component {
    async componentDidMount() {
        await this.props.getVideo();
    }

    render() {
        const readingHistory = [
            (
                <div className="home-faca-parte-logged-slide-item">
                    <img
                        src={historicoLeitura1}
                        key="home-reading-history-0"
                    />
                </div>
            ),
            (
                <div className="home-faca-parte-logged-slide-item">
                    <img
                        src={historicoLeitura1}
                        key="home-reading-history-0"
                    />
                </div>
            ),
            (
                <div className="home-faca-parte-logged-slide-item">
                    <img
                        src={historicoLeitura1}
                        key="home-reading-history-0"
                    />
                </div>
            )
        ];

        const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
                type="button"
            >
                <img 
                    src={leftChevron} 
                    alt="Seta para a esquerda"
                />
            </button>
        );
        
        const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
            <button
                {...props}
                className={
                    "slick-next slick-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
                type="button"
            >
                <img 
                    src={rightChevron} 
                    alt="Seta para a esquerda"
                />
            </button>
        );

        return (
            <section id="home-faca-parte" className="home-faca-parte d-flex align-items-center flex-column justify-content-center">
                <div className="home-faca-parte-box container">
                    <If test={ this.props.authReducer.validLogin === false }>
                        <Row>
                            <Grid 
                                cols="12 12 12 6"
                                className="home-faca-parte-box-text-div"
                            >
                                <h3 className="text-pink f-w-600 f-32 f-montserrat m-b-16">
                                    <span className="d-block">Faça parte</span>
                                    <span className="d-block">do clube</span>
                                </h3>
                                <p className="f-lato f-18 text-silver m-b-24">
                                São mais de 120 títulos dos mais variados temas na palma da sua mão! Esportes, gastronomia, negócios, celebridades, tecnologia… Tudo pelo preço de UMA REVISTA SÓ!
                                </p>
                                {/* <TheButton 
                                    text="Assine por R$ 12,99 ao mês"
                                    className="bg-pink btn-md text-white w-100 m-b-md-70 home-faca-parte-box-btn-faca-parte"
                                    onClick={ () => window.location.href = 'https://ads.clubederevistas.com/lp3/#faca-parte-do-clube'}
                                /> */}

                                <a href="https://ads.clubederevistas.com/lp3/#faca-parte-do-clube" target="_blank"
                                rel="noopener noreferrer"
                                className="bg-pink btn-md text-white w-100 m-b-md-70 home-faca-parte-box-btn-faca-parte btn the-button">Assine por R$ 12,99 ao mês</a>
                            </Grid>
                            <Grid 
                                cols="12 12 12 6"
                                className="d-flex justify-content-center flex-column align-items-center"
                            >
                                {/* <img 
                                    className="home-faca-parte-thumbnail m-b-24"
                                    src={ this.props.homeFacaParteReducer ? this.props.homeFacaParteReducer.videoThumbnail : "" }
                                    alt="Thumbnail de vídeo"
                                />
                                <a 
                                    className="home-faca-parte-video-play"
                                    onClick={ () => this.props.setVideoModalOpened(true) }
                                ></a> */}

                                        <div className="iframe-youtube-lp2 iframe-youtube-home">
                                        <VideoEmbed 
                                        videoId={'6LC1qZfDSSU'} />
                                        </div>

                                
                                
                                <div
                                    className="f-lato f-w-500 f-16 text-silver text-center"
                                >

                                    <p>Assista ao vídeo e saiba mais</p>
                                    {/* <span
                                        className="cursor-pointer"
                                        onClick={ () => this.props.setVideoModalOpened(true) }
                                    >
                                        Assista ao vídeo e saiba mais
                                    </span> */}
                                </div>
                            </Grid>
                        </Row>
                    </If>
                    <If test={ this.props.authReducer.validLogin === true }>
                        <Row className="align-items-center">
                            <Grid
                                cols="12 12 12 4"
                                className="d-flex flex-column home-faca-parte-box-text-div-logged text-center-md"
                            >
                                <h3 className="f-montserrat f-w-700 f-32 m-b-md-16 m-b-18">
                                    Seu <span className="text-pink"> histórico de leitura</span>
                                </h3>
                                <span className="f-lato f-w-400 f-16 text-silver m-b-24 m-b-md-28">
                                    As revistas que você leu nos últimos 30 dias.
                                </span>
                                {/* <span
                                    className="f-16 text-pink f-w-500 cursor-pointer home-faca-parte-complete-historic-logged d-none d-xl-block"
                                    onClick={ () => this.props.setVideoModalOpened(true) }
                                >
                                    Ver histórico completo
                                </span> */}
                            </Grid>
                            <Grid
                                cols="12 12 12 8"
                            >
                                <div className="container">
                                    <SlickCarousel
                                        dots={false}
                                        infinite={true}
                                        slidesToShow={2}
                                        slidesToScroll={1}
                                        autoplay={false}
                                        speed={500}
                                        autoplaySpeed={0}
                                        cssEase={"linear"}
                                        arrows={true}
                                        initialSlide={2}
                                        nextArrow={<SlickArrowRight />}
                                        prevArrow={<SlickArrowLeft />}
                                        centerMode={true}
                                        className="d-none d-xl-block"
                                    >
                                        { readingHistory.map(element => { return element }) }
                                    </SlickCarousel>
                                    <SlickCarousel
                                        dots={false}
                                        infinite={true}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        autoplay={false}
                                        speed={500}
                                        autoplaySpeed={0}
                                        cssEase={"linear"}
                                        arrows={true}
                                        initialSlide={2}
                                        nextArrow={<SlickArrowRight />}
                                        prevArrow={<SlickArrowLeft />}
                                        centerMode={true}
                                        className="d-block d-xl-none"
                                    >
                                        { readingHistory.map(element => { return element }) }
                                    </SlickCarousel>
                                    {/* <span
                                        className="f-16 text-pink f-w-500 cursor-pointer home-faca-parte-complete-historic-logged d-block d-xl-none m-0-auto-md"
                                        onClick={ () => this.props.setVideoModalOpened(true) }
                                    >
                                        Ver histórico completo
                                    </span> */}
                                </div>
                            </Grid>
                        </Row>
                    </If>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    homeFacaParteReducer: state.homeFacaParteReducer,
    authReducer: state.authReducer
});
const mapDispatchToProps = dispatch => bindActionCreators({
    getVideo,
    setVideoModalOpened
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeFacaParte);