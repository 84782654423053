export const getLancamentos = () => {
    return dispatch => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["home"] &&
                data["home"]["lancamentos-mais-recentes"])
            {
                const lancamentosList = [];

                await data["home"]["lancamentos-mais-recentes"].forEach(async (lancamento) => {
                    const lancamentoImage = await require(`../../assets/images/lancamentos-revistas/${lancamento.imagem}`);
                    const lancamentoImageAlt = lancamento["imagem-alt"];
                    const lancamentoCode = lancamento.codigo;
                    const lancamentoDate = lancamento.data;
                    const lancamentoTitle = lancamento.titulo;
                    const lancamentoDescricao = lancamento.descricao;
                    const lancamentoId = lancamento.id;

                    const lancamentoObj = {
                        image: lancamentoImage.default,
                        imageAlt: lancamentoImageAlt,
                        code: lancamentoCode,
                        date: lancamentoDate,
                        descricao: lancamentoDescricao,
                        title: lancamentoTitle,
                        id: lancamentoId,
                    }

                    lancamentosList.push(lancamentoObj);
                });

                dispatch({ type: "SET_LANCAMENTOS_HOME", payload: lancamentosList });
            }
        })
    }
}