export const setBannersLogado = () => {
    return (dispatch, getState) => {
        fetch("data.json", { headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }})
        .then(response => {
            if (response.status === 200) return response.json();
        })
        .then(async data => {
            if (data["home"] &&
                data["home"]["banner-logado"])
            {
                const banners = [];

                await data["home"]["banner-logado"].forEach(async (banner) => {
                    const bannerImagem = await require("../../assets/images/home/" + banner.imagem);
                    const bannerObj = {
                        id: banner.id,
                        titulo: banner.titulo,
                        texto: banner.texto,
                        "link-botao": banner["link-botao"],
                        imagem: bannerImagem.default
                    };

                    banners.push(bannerObj);
                });

                dispatch({ type: "SET_HOME_BANNERS_LOGADO", payload: banners });

                if (!getState().bannerHomeReducer.currentBannerLogado) {
                    dispatch({ type: "SET_CURRENT_HOME_BANNER_LOGADO", payload: banners[0] });
                }
            }
        })
    }
}