import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import $ from "jquery";

import AssetBannerHome from "../../assets/images/home/banner-home-mobile.png"
import BannerHomeLogado1 from "../../assets/images/home/banner-home-logado-1.png";
import "./BannerHome.scss";

import If from "../If/If";
import Row from "../Row/Row";
import Grid from "../Grid/Grid";
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import TheButton from "../TheButton/TheButton";

import {
    setBannersLogado
} from "../../redux/actions/BannerHomeActions";

class BannerHome extends Component {
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        await this.props.setBannersLogado();
    }

    openLink(link) {
        console.log('openLink')
        console.log('link', link)

        window.open(link, '_blank')
    }

    scrollTo(element, difference) {
        const scrollTop = $(element).offset().top;
        window.scrollTo(0, scrollTop - (difference ? difference : 0));
    }

    render() {
        const { validLogin } = this.props.authReducer;
        const { bannersLogado } = this.props.bannerHomeReducer;

        return (
            <section className="banner-home">
                <div className="banner-home-wrap">
                    <If test={ validLogin === false }>
                        <div className="banner-home-content">
                            <div className="banner-home-container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="banner-home-title">Mais de <b className="text-pink">120 revistas</b> por <b>R$ 12,99 por mês</b></h2>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="banner-home-subtitle">É isso mesmo: só R$ 12,99 ao mês por 1 ano! <b>E 1º mês grátis!</b></h6>
                                        </div>
                                    </div>

                                    <div className="banner-home-image-mobile">
                                        <img src={AssetBannerHome} alt="" />
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <a 
                                            href="https://ads.clubederevistas.com/lp3/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="banner-home-cta">Faça parte do Clube</a>
                                        </div>
                                    </div>
        
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="banner-home-saiba-mais">Ou <a href="#home-faca-parte">saiba mais detalhes</a></p>
                                        </div>
                                    </div>

                                    <div className="row d-block d-md-none">
                                        <div className="col-12">
                                        <a
                                            onClick={ () => this.scrollTo("#home-titulos-destaque", 82) }
                                            className="banner-home-arrow-link"
                                        ></a>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </If>
                    <If test={ validLogin === true }>
                        <div className="banner-home-logado-content d-none d-md-flex">
                            <div className="banner-home-logado-container container">
                                <SlickCarousel
                                    centerPadding="0"
                                    dots={true}
                                    dotsClass={"slick-dots banner-home-logado-banner-selector"}
                                >
                                    {bannersLogado.map((banner, index) => (
                                        <Row key={index}>
                                            <Grid
                                                cols="6"
                                                className="d-flex align-items-start flex-column justify-content-center"
                                            >
                                                <h2 className="banner-home-logado-title">{ banner ? banner.titulo : "" }</h2>
                                                <h3 className="banner-home-logado-text">{ banner ? banner.texto : "" }</h3>
                                                <TheButton
                                                    text="Veja os títulos"
                                                    className="bg-white btn-lg text-pink banner-home-logado-button"
                                                    path={ banner ? banner["link-botao"] : "" }
                                                />
                                            </Grid>
                                            <Grid cols="6">
                                                <img
                                                    src={ banner ? banner.imagem : "" }
                                                    alt="Imagem de dispositivo móvel"
                                                />
                                            </Grid>
                                        </Row>
                                    ))}
                                </SlickCarousel>
                            </div>
                        </div>
                        <div className="banner-home-logado-content-mobile d-block d-md-none">
                            <div className="banner-home-logado-container container">
                                <SlickCarousel
                                    dots={true}
                                    dotsClass={"slick-dots banner-home-logado-banner-selector"}
                                    infinite={false}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    autoplay={false}
                                    speed={0}
                                    cssEase={"linear"}
                                    initialSlide={0}
                                    centerMode={false}
                                    centerPadding={"0px"}
                                >
                                    {bannersLogado.map((banner, index) => (
                                        <div key={`banner-home-logado-slide-${index}`}>
                                            <h2 className="banner-home-logado-title">{ banner ? banner.titulo : "" }</h2>
                                            <h3 className="banner-home-logado-text">{ banner ? banner.texto : "" }</h3>
                                            <img
                                                src={ banner ? banner.imagem : "" }
                                                alt="Imagem de dispositivo móvel"
                                                className="banner-home-logado-image"
                                            />
                                            <TheButton
                                                text="Veja os títulos"
                                                className="bg-white btn-lg text-pink banner-home-logado-button"
                                                containerClass="d-flex justify-content-center"
                                                path={ banner ? banner["link-botao"] : "" }
                                            />
                                        </div>
                                    ))}
                                </SlickCarousel>
                            </div>
                        </div>
                    </If>
                </div>

                <If test={ validLogin === false }>
                    <div className="banner-home-arrow-link-desktop">
                        <a 
                            onClick={ () => this.scrollTo("#home-titulos-destaque", 82) }
                            className="banner-home-arrow-link"
                        ></a>
                    </div>
                </If>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    bannerHomeReducer: state.bannerHomeReducer
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setBannersLogado
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BannerHome);