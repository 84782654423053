import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCategoriesFromJson } from "../../redux/actions/Menu";
import $ from "jquery";

import {
    setFaqOpened,
    setLoginFormOpened
} from "../../redux/actions/Home";

import {
    getCapasRevistasFromJson
} from "../../redux/actions/Menu";

import {
    getMaterias,
    getMateriasLogado,
    increaseNumMateriasLogadoToShow
} from "../../redux/actions/HomeMaterias";

import AssetMateriaCover1 from "../../assets/images/materias/1.jpg"
import AssetMateriaCover2 from "../../assets/images/materias/2.jpg"
import AssetMateriaCover3 from "../../assets/images/materias/3.jpg"
import "./HomeMaterias.scss"

import HomeFooter from "../../components/HomeFooter/HomeFooter";
import SlickCarousel from "../SlickCarousel/SlickCarousel";
import If from "../If/If";


export class HomeMaterias extends Component {
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        await this.props.getMateriasLogado();
        await this.props.getMaterias();
    }

    componentDidUpdate() {
        setTimeout(() => this.adjustMateriasHeight(), 500);
    }

    async adjustMateriasHeight() {
        let maxHeight = 0;
        await $(".home-materias-slide-card").each((index, element) => {
            const elementHeight = $(element).height();

            if (elementHeight > maxHeight) {
                maxHeight = elementHeight;
            }
        });

        await $(".home-materias-slide-card").each((index, element) => {
            $(element).css("height", maxHeight);
        });
    }

    render() {
        return (
            <section className="home-materias">
                <div className="row">
                    <div className="col-12">
                        <h2 className="home-materias-titulo">Matérias <b className="text-pink">em destaque</b></h2>
                    </div>
                </div>

                <If test={ this.props.authReducer.validLogin === false }>
                    <div className="home-materias-slider d-block d-md-none">
                        <SlickCarousel
                            dots={false}
                            infinite={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            autoplay={false}
                            cssEase={"linear"}
                            initialSlide={1}
                            centerMode={true}
                            centerPadding={"60px"}
                        >
                            { this.props.HomeMateriasReducer.materias.map((materia, index) => (
                                <div className="home-materias-slide-wrap" key={materia.id}>
                                    <div className="home-materias-slide-card">
                                        <img src={materia.cover} />

                                        <div className="home-materias-slide-content">
                                            <small>{materia.revista} | {materia.edicao} | {materia.data}</small>
                                            <h4>{materia.titulo}</h4>
                                            <p>{materia.body}</p>
                                            <a href={materia.link}>Continue lendo</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlickCarousel>
                    </div>

                    <div className="home-materias-desktop-list d-none d-md-block">
                        <div className="container">
                            <div className="row">
                                { this.props.HomeMateriasReducer.materias.map((materia, index) => (
                                    <div className="col-4" key={materia.id}>
                                        <div className="home-materias-slide-card">
                                            <img src={materia.cover} />
                                            <div className="home-materias-slide-content">
                                                <small>{materia.revista} | {materia.edicao} | {materia.data}</small>
                                                <h4>{materia.titulo}</h4>
                                                <p>{materia.body}</p>
                                                <a href={materia.link}>Continue lendo</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </If>
                <If test={ this.props.authReducer.validLogin === true }>
                    <div className="home-materias-desktop-list d-none d-md-block">
                        <div className="container">
                            <div className="row">
                                { this.props.HomeMateriasReducer.materiasLogado
                                    .slice(0, this.props.HomeMateriasReducer.numMateriasLogadoToShow).map((materia, index) => (
                                        <div className="col-4" key={materia.id}>
                                            <div className="home-materias-slide-card">
                                                <img src={materia.cover} />
                                                <div className="home-materias-slide-content">
                                                    <small>{materia.revista} | {materia.edicao} | {materia.data}</small>
                                                    <h4>{materia.titulo}</h4>
                                                    <p>{materia.body}</p>
                                                    <a href={materia.link}>Continue lendo</a>
                                                </div>
                                            </div>
                                        </div>
                                )) }
                            </div>
                            <If test={ !(this.props.HomeMateriasReducer.materiasLogado.length === this.props.HomeMateriasReducer.numMateriasLogadoToShow) }>
                                <div className="row d-flex justify-content-center">
                                    <span 
                                        className="f-lato f-16 text-pink f-w-500 cursor-pointer text-decor-line"
                                        onClick={ () => this.props.increaseNumMateriasLogadoToShow(this.props.HomeMateriasReducer.materiasLogado.length) }
                                    >Ver mais artigos</span>
                                </div>
                            </If>
                        </div>
                    </div>
                    <div className="home-materias-slider d-block d-md-none">
                        <SlickCarousel
                            dots={false}
                            infinite={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            autoplay={false}
                            cssEase={"linear"}
                            initialSlide={1}
                            centerMode={true}
                            centerPadding={"60px"}
                        >
                            { this.props.HomeMateriasReducer.materiasLogado.map((materia, index) => (
                                <div className="home-materias-slide-wrap" key={materia.id}>
                                    <div className="home-materias-slide-card">
                                        <img src={materia.cover} />

                                        <div className="home-materias-slide-content">
                                            <small>{materia.revista} | {materia.edicao} | {materia.data}</small>
                                            <h4>{materia.titulo}</h4>
                                            <p>{materia.body}</p>
                                            <a href={materia.link}>Continue lendo</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SlickCarousel>
                    </div>
                </If>
                <HomeFooter />
            </section>
        );
    }
}

const mapStateToProps = state => ({ 
    MenuReducer: state.MenuReducer,
    HomeMateriasReducer: state.HomeMateriasReducer,
    authReducer: state.authReducer,
});

const mapDispatchToProps = dispatch => bindActionCreators({ 
    getCategoriesFromJson,
    getCapasRevistasFromJson,
    getMaterias,
    setFaqOpened,
    setLoginFormOpened,
    getMateriasLogado,
    increaseNumMateriasLogadoToShow
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeMaterias);